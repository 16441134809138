var render = function () {
  var _vm$rftBid, _vm$rftBid$product, _vm$rftBid$product2, _vm$rftBid$product3, _vm$rftBid$product$pr, _vm$rftBid2, _vm$rftBid2$product, _vm$rftBid2$product$p, _vm$rftBid$product$in, _vm$rftBid3, _vm$rftBid3$product, _vm$rftBid3$product$i, _vm$rftBid$product$PB, _vm$rftBid4, _vm$rftBid4$product;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-form', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.isLoading,
      expression: "isLoading"
    }],
    ref: "orgProductForm",
    staticClass: "mb-5",
    attrs: {
      "model": _vm.form,
      "label-width": "200px"
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.CombinationUtil.headermap_product.requested_product_name,
      "prop": "medicinal_product"
    }
  }, [_c('b', {
    domProps: {
      "innerHTML": _vm._s(_vm.sourceProduct.requested_product_name)
    }
  })]), _c('el-form-item', {
    attrs: {
      "label": _vm.CombinationUtil.headermap_bid.trade_product_name,
      "prop": "trade_product"
    }
  }, [_c('el-input', {
    attrs: {
      "value": (_vm$rftBid = _vm.rftBid) === null || _vm$rftBid === void 0 ? void 0 : (_vm$rftBid$product = _vm$rftBid.product) === null || _vm$rftBid$product === void 0 ? void 0 : _vm$rftBid$product.name,
      "readonly": ""
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": _vm.CombinationUtil.headermap_product.unit_quantity,
      "prop": "unit_quantity"
    }
  }, [_c('el-input', {
    attrs: {
      "value": _vm.sourceProduct.unit_quantity,
      "readonly": ""
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": _vm.CombinationUtil.headermap_bid.trade_product_container_size,
      "prop": "pack_size"
    }
  }, [_c('el-input', {
    attrs: {
      "value": (((_vm$rftBid$product2 = _vm.rftBid.product) === null || _vm$rftBid$product2 === void 0 ? void 0 : _vm$rftBid$product2.Container_Qty) + " " + ((_vm$rftBid$product3 = _vm.rftBid.product) === null || _vm$rftBid$product3 === void 0 ? void 0 : _vm$rftBid$product3.Container_Qty_Type)),
      "readonly": ""
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": _vm.CombinationUtil.headermap_bid.pbs_code,
      "prop": "pack_size"
    }
  }, [_c('el-input', {
    attrs: {
      "value": ("" + ((_vm$rftBid$product$pr = (_vm$rftBid2 = _vm.rftBid) === null || _vm$rftBid2 === void 0 ? void 0 : (_vm$rftBid2$product = _vm$rftBid2.product) === null || _vm$rftBid2$product === void 0 ? void 0 : (_vm$rftBid2$product$p = _vm$rftBid2$product.product_info) === null || _vm$rftBid2$product$p === void 0 ? void 0 : _vm$rftBid2$product$p.PBSCode) !== null && _vm$rftBid$product$pr !== void 0 ? _vm$rftBid$product$pr : (_vm$rftBid$product$in = (_vm$rftBid3 = _vm.rftBid) === null || _vm$rftBid3 === void 0 ? void 0 : (_vm$rftBid3$product = _vm$rftBid3.product) === null || _vm$rftBid3$product === void 0 ? void 0 : (_vm$rftBid3$product$i = _vm$rftBid3$product.info) === null || _vm$rftBid3$product$i === void 0 ? void 0 : _vm$rftBid3$product$i.PBSCode) !== null && _vm$rftBid$product$in !== void 0 ? _vm$rftBid$product$in : (_vm$rftBid$product$PB = (_vm$rftBid4 = _vm.rftBid) === null || _vm$rftBid4 === void 0 ? void 0 : (_vm$rftBid4$product = _vm$rftBid4.product) === null || _vm$rftBid4$product === void 0 ? void 0 : _vm$rftBid4$product.PBSCode) !== null && _vm$rftBid$product$PB !== void 0 ? _vm$rftBid$product$PB : 'N/A')),
      "readonly": ""
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": _vm.CombinationUtil.headermap_bid.pbs_price,
      "prop": "pack_size"
    }
  }, [_c('el-input', {
    attrs: {
      "value": _vm.pbsPrice,
      "readonly": ""
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": _vm.CombinationUtil.headermap_bid.pbs_date,
      "prop": "pack_size"
    }
  }, [_c('el-input', {
    attrs: {
      "value": _vm.pbsDate,
      "readonly": ""
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": _vm.CombinationUtil.headermap_bid.unit_net_price,
      "prop": "price_per_pack"
    }
  }, [_c('el-input-number', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "placeholder": "Price Per Pack",
      "disabled": !_vm.editable && !_vm.isNewAnswer,
      "min": 0
    },
    model: {
      value: _vm.form.unit_net_price,
      callback: function ($$v) {
        _vm.$set(_vm.form, "unit_net_price", $$v);
      },
      expression: "form.unit_net_price"
    }
  }), _vm.rftBid.rftBundle_uuid ? _c('span', [_vm._v(" * This pack has a bundle price with bundle id "), _c('el-link', {
    on: {
      "click": function ($event) {
        return _vm.handleBundleEdit(_vm.rftBid);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.rftBid.rftBundle_uuid) + " ")]), _vm._v(", please update the bundle price when you update this bid price. ")], 1) : _vm._e()], 1), _c('el-form-item', {
    attrs: {
      "label": _vm.CombinationUtil.headermap_bid.cogs,
      "prop": "cogs"
    }
  }, [_c('el-input-number', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "placeholder": "COGS",
      "disabled": !_vm.editable && !_vm.isNewAnswer,
      "min": 0
    },
    model: {
      value: _vm.form.cogs,
      callback: function ($$v) {
        _vm.$set(_vm.form, "cogs", $$v);
      },
      expression: "form.cogs"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": _vm.CombinationUtil.headermap_bid.bid_comment,
      "prop": "bid_comment"
    }
  }, [_c('el-input', {
    staticClass: "w-100",
    attrs: {
      "disabled": !_vm.editable && !_vm.isNewAnswer,
      "placeholder": "Comment"
    },
    model: {
      value: _vm.form.comment,
      callback: function ($$v) {
        _vm.$set(_vm.form, "comment", $$v);
      },
      expression: "form.comment"
    }
  })], 1), _vm._t("default")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }